<template>
  <v-card flat>
    <v-card-text>
      <d-tax-form
        :taxData="taxDetails"
        :healthInsuranceProviders="healthInsuranceProviders"
        :healthInsuranceProvider="healthInsuranceProvider"
        :contract="contract"
        @validation="$emit('validation', $event)"
        @reset="$emit('reset')"
      >
      </d-tax-form>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiSquareEditOutline, mdiDeleteOutline, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js';

import DTaxForm from '@/components/profile-form/tax-data-form.vue';

export default {
  components: { DTaxForm },
  props: ['taxDetails', 'contract', 'healthInsuranceProviders', 'healthInsuranceProvider'],
  setup() {
    return {
      taxData: {
        nationality: '',
        taxResidence: '',
        occupation: '',
        taxClass: '',
        taxClassFactor: '',
        capitalFormationContribution: '',
        childAllowance: '',
        churchTax: false,
        healthInsuranceType: '',
        healthInsuranceContributionRate: 0,
        healthInsurancePrivateContribution: 0,
        careInsuranceContribution: 0,
        incomeTaxContribution: 0,
        grossIncome: 0,
        netIncome: 0,
        beneficiaries: '',
      },
      icons: {
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
    };
  },
};
</script>
