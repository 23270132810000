import axios from '@/plugins/axios';

export const getRequestsHistory = (id, limit = 100, offset = 0, sorting = 'DESC', filter = '') => {
  const skipPart = `&skip=${offset}`;
  const limitPart = `&limit=${limit}`;
  const sortingPart = `&sort=createdDate:${sorting}`;
  const filterPart = filter ? `&status=${filter}` : '';

  return axios.get(`/requests?employeeId=${id}${skipPart}${limitPart}${sortingPart}${filterPart}`);
};

export const createRequest = (payload) => axios.post('/requests', payload);
export const getRequestTypes = () => axios.get('/requests/types');
export const getRequestStatuses = () => axios.get('/requests/statuses');
export const getFieldsByRequestTypeId = (id) => axios.get(`/requests/types/${id}/fields`);
