<template>
  <div id="personal-profil">
    <v-row class="mt-md-6 mt-1">
      <v-col v-if="userDetails && companyConfig && activeRole !== 'ROLE_ADMIN'" cols="12" md="5" lg="4">
        <user-bio-panel
          class="mb-2"
          :company-config="companyConfig"
          :user-data="userDetails"
          :is-my-profile="isMyProfile"
          :personal-details="userDetails.personalDetails"
          :sender="userData"
          :user="userDetails"
          @switchToTab="switchToTabRequests"
        />
      </v-col>

      <v-col v-if="activeRole === 'ROLE_ADMIN'" cols="12" md="6" lg="7">
        <user-configuration />
      </v-col>

      <v-col v-if="userDetails && companyConfig && activeRole !== 'ROLE_ADMIN'" cols="12" md="7" lg="8">
        <div>
          <v-alert
            v-if="activeRole == 'ROLE_EMPLOYEE' && canChangeContract"
            type="info"
            dense
            class="alert-profile-form mb-md-10"
          >
            <div @click="$router.push({ name: 'meine-bav' })" v-html="$t('personalProfileInfoText')"></div>
          </v-alert>
          <v-alert
            v-if="(activeRole == 'ROLE_BAV_MANAGER' && canChangeContract && userTab == 1) || userTab == 2"
            type="info"
            dense
            class="alert-profile-form mb-md-10"
          >
            <div @click="userTab = 3" v-html="$t('personalProfileInfoTextHRAdmin')"></div>
          </v-alert>
        </div>

        <v-tabs v-model="userTab" show-arrows grow active-class="personal-profile__tab--active">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <div class="d-flex flex-column align-center justify-center">
              <v-icon v-if="!$vuetify.breakpoint.smAndDown" size="20" class="pt-9">{{ tab.icon }}</v-icon>
              <span class="text-sm personal-profile__tab-text mb-md-5">{{ $t(tab.title) }}</span>
            </div>
          </v-tab>
        </v-tabs>

        <v-card class="v-card--cc mt-8">
          <v-tabs-items id="user-tabs-content" v-model="userTab">
            <v-tab-item v-if="activeRole != 'ROLE_EMPLOYEE'">
              <v-card flat>
                <user-tab-shared-documents :employee="$route.params.id"> </user-tab-shared-documents>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <user-tab-personal
                v-if="!loading"
                :personal-details="userDetails.personalDetails"
                :companyData="companyConfig"
                @validation="setPersonalDataStatus"
                @reset="resetPersonalDetails"
              />
            </v-tab-item>
            <v-tab-item>
              <user-tab-financial
                v-if="!loading"
                :companyData="companyConfig"
                :contract="userContract"
                :tax-details="userDetails.taxDetails"
                :healthInsuranceProviders="healthInsuranceProviders"
                :healthInsuranceProvider="healthInsuranceProvider"
                @validation="setTaxDataStatus"
                @reset="resetPersonalDetails"
              />
            </v-tab-item>
            <v-tab-item v-if="activeRole != 'ROLE_KEY_ACCOUNT' && canChangeContract">
              <user-tab-change-requests ref="tabChangeRequests" :user="userDetails" :sender="userData">
              </user-tab-change-requests>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <ModalWindow :is-open="leaveConfirmationModal" warning>
      <template #content>
        <p class="text-base">{{ $t('doWishToContinueWithoutSaving') }}</p>
      </template>
      <template #actions>
        <v-btn outlined color="primary" @click="leaveConfirmationModal = false">{{ $t('buttonCancel') }}</v-btn>
        <v-btn color="primary" @click="confirmNavigationWithoutSaving">{{ $t('buttonConfirm') }}</v-btn>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { onMounted, ref, getCurrentInstance, computed } from '@vue/composition-api';
import { mdiAccountOutline, mdiFinance, mdiFileDocumentMultiple, mdiFileEdit } from '@mdi/js';
import { getEmployeeById, getContractByEmployeeId } from '@/api/employee';
import { getCompanyById } from '@/api/companies';
import { CONTRACT_SIGNED, CONTRACT_SUSPENDED, ROLE_ADMIN } from '@/constants';
import { getHealthInsuranceProviders, getHealthInsuranceProviderById } from '@/api/health-insurance';
import UserBioPanel from './personal-profile/user-bio-panel/UserBioPanel.vue';
import UserConfiguration from './personal-profile/user-configuration/UserConfiguration';
import UserTabPersonal from './personal-profile/user-tab-personal/UserTabPersonal.vue';
import UserTabFinancial from './personal-profile/user-tab-financial/UserTabFinancial.vue';
import UserTabChangeRequests from '@/components/change-request/MyChangeRequests.vue';
import UserTabSharedDocuments from '@/components/table/SharedUserDocumentsTable.vue';
import ModalWindow from '@/components/modal/ModalWindow';

export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit('documents/__RESET');
    if (this.isEditingPersonalData || this.isEditingTaxData) {
      this.showConfirmDialog(to.path);
      return;
    }
    next();
  },
  components: {
    UserBioPanel,
    UserTabPersonal,
    UserTabFinancial,
    UserTabChangeRequests,
    UserTabSharedDocuments,
    ModalWindow,
    UserConfiguration,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { userData } = vm.$store.getters;
    const isMyProfile = userData.id === vm.$route.params.id;
    const companyConfig = ref(null);
    const userDetails = ref(null);
    const userTab = ref(null);
    const userContract = ref(null);
    const loading = ref(false);
    const isEditingPersonalData = ref(false);
    const isEditingTaxData = ref(false);
    const leaveConfirmationModal = ref(false);
    const catchedNavigationPath = ref(null);
    const { activeRole } = vm.$store.getters;
    const healthInsuranceProviders = ref([]);
    const healthInsuranceProvider = ref({
      name: null,
      rate: null,
    });
    const fetchExistingProvider = ref(false);
    const switchToTabRequests = () => {
      if (userTab.value !== 3) {
        userTab.value = 3;
      } else {
        vm.$refs.tabChangeRequests.fetchHistory(userDetails.value.id);
      }
    };

    const canChangeContract = computed(() => {
      const isValidContractStatus =
        userContract.value &&
        (userContract.value.contractStatus === CONTRACT_SIGNED ||
          userContract.value.contractStatus === CONTRACT_SUSPENDED);
      return activeRole !== 'ROLE_KEY_ACCOUNT' && isValidContractStatus;
    });

    const tabs = computed(() => {
      return activeRole == 'ROLE_EMPLOYEE'
        ? [
            {
              icon: mdiAccountOutline,
              title: 'personalProfileHeadingPersonal',
            },
            { icon: mdiFinance, title: 'personalProfileHeadingFinancial' },
          ]
        : [
            { icon: mdiFileDocumentMultiple, title: 'headlineSharedDocuments' },
            ...(canChangeContract.value ? [{ icon: mdiFileEdit, title: 'changeRequest', value: 2 }] : []),
            {
              icon: mdiAccountOutline,
              title: 'personalProfileHeadingPersonal',
            },

            { icon: mdiFinance, title: 'personalProfileHeadingFinancial' },
          ];
    });

    const getDetails = async (id) => {
      loading.value = true;
      getEmployeeById(id)
        .then((res) => {
          userDetails.value = res.data;
          userDetails.value.personalDetails.languagePreference = {
            language: userDetails.value.languagePreference.language,
          };
          userDetails.value.taxDetails = {
            ...userDetails.value.taxDetails,
            grossIncome: userDetails.value.taxDetails.grossIncome,
            healthInsuranceContributionRate: Number(userDetails.value.taxDetails.healthInsuranceContributionRate) * 100,
          };
          getHealthInsuranceProviders(0, 1000 /* Do we need pagination here? */).then((res) => {
            healthInsuranceProviders.value = res.data.page;
            if (userDetails.value.taxDetails.healthInsuranceId) {
              fetchExistingProvider.value = true;
              getHealthInsuranceProviderById(userDetails.value.taxDetails.healthInsuranceId).then((res) => {
                healthInsuranceProvider.value.name = res.data.providerName;
                healthInsuranceProvider.value.rate = res.data.providerContribution;
                fetchExistingProvider.value = false;
              });
            }
          });

          if (isMyProfile) {
            companyConfig.value = vm.$store.state.auth.companyConfig;
          } else {
            getCompanyById(userDetails.value.company.id).then((res) => {
              companyConfig.value = res.data;
            });
          }

          getContractByEmployeeId(id)
            .then((res) => {
              userContract.value = res;
              loading.value = false;
            })
            .catch(() => {
              userContract.value = {
                beneficiaryDetails: {
                  beneficiaryType: '',
                  beneficiaryFirstName: '',
                  beneficiaryLastName: '',
                },
              };
              loading.value = false;
            });
        })
        .catch(() => {
          vm.$router.push('/error-404');
        });
    };

    const resetPersonalDetails = () => {
      isEditingPersonalData.value = false;
      isEditingTaxData.value = false;
      getDetails(vm.$route.params.id);
    };

    const setPersonalDataStatus = (v) => {
      isEditingPersonalData.value = v;
    };

    const setTaxDataStatus = (v) => {
      isEditingTaxData.value = v;
    };

    const showConfirmDialog = (path) => {
      leaveConfirmationModal.value = true;
      catchedNavigationPath.value = path;
    };

    const confirmNavigationWithoutSaving = () => {
      isEditingPersonalData.value = false;
      isEditingTaxData.value = false;
      leaveConfirmationModal.value = false;
      vm.$router.push(catchedNavigationPath.value);
    };
    onMounted(() => {
      if (activeRole !== ROLE_ADMIN) {
        getDetails(vm.$route.params.id);
      }
    });

    return {
      tabs,
      userTab,
      companyConfig,
      userDetails,
      userData,
      userContract,
      loading,
      isEditingPersonalData,
      isEditingTaxData,
      leaveConfirmationModal,
      canChangeContract,
      confirmNavigationWithoutSaving,
      resetPersonalDetails,
      setPersonalDataStatus,
      setTaxDataStatus,
      showConfirmDialog,
      activeRole,
      isMyProfile,
      switchToTabRequests,
      healthInsuranceProviders,
      fetchExistingProvider,
      healthInsuranceProvider,
    };
  },
};
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
.alert-profile-form {
  a {
    color: #fff;
    border-bottom: 1px solid;
  }
}
.personal-profile {
  padding-top: 33px !important;
  &__status {
    position: fixed;
    width: 100%;
    height: 42px;
    left: 0px;
    top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    background: #f9b60d;
    z-index: 5000;
  }
  .v-tabs-bar.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows)
    > .v-slide-group__wrapper
    > .v-tabs-bar__content
    > .v-tab:first-of-type {
    margin-left: unset !important;
  }

  .v-tabs-bar {
    height: 65px !important;
  }

  .v-tabs-items {
    background-color: transparent !important;
  }
  .v-tab {
    font-size: 14px !important;
    text-transform: none !important;
    padding-top: 13px;
    padding-bottom: 8px;
    letter-spacing: normal !important;
  }
  &-next {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
  }
  &__tab {
    &-text {
      color: #898989;
      margin-top: 12px;
      text-transform: none;
    }
    &--active {
      background-color: rgba(108, 189, 255, 0.5);
      font-weight: bold;
      .personal-profile__tab-text {
        color: #2830ce;
        font-weight: bold;
      }
    }
  }
}
.v-tabs-overlay {
  position: relative;
}
.v-tabs-overlay::after {
  background-color: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '';
  z-index: 4;
  transition: opacity 250ms ease-in-out;
  opacity: 0;
  pointer-events: none; /* Pass events through the "fade" layer */
}
.scrolling::after {
  opacity: 1;
}

@media screen and (max-width: 960px) {
  .personal-profile {
    .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
      display: flex !important;
      word-break: break-word;
      white-space: pre-wrap;
    }
    .v-tabs-bar {
      height: 50px !important;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
    }
    .v-tab {
      max-width: 50px;
      line-height: 20px !important;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 !important;
    }
    &__tab-text {
      max-width: 95px;
      font-size: 10px !important;
      margin: 0 !important;
    }
    &-tabs {
      padding: 0;
    }
  }
}
</style>
