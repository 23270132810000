<template>
  <div class="my-change-request my-change-request__table">
    <v-card class="mb-5 mt-0 my-change-request__table-card">
      <v-card-text
        class="d-flex align-center flex-wrap pb-0"
        :class="[{ 'border--dark': !$vuetify.breakpoint.smAndDown }]"
      >
        <v-row :no-gutters="$vuetify.breakpoint.smAndDown" :class="[{ 'px-3': !$vuetify.breakpoint.smAndDown }]">
          <v-col cols="12" md="4" sm="12" class="px-0">
            <v-select
              v-model="requestFilter"
              :items="requestStatuses"
              single-line
              outlined
              dense
              hide-details
              clearable
              :label="$t('myBavStatusFilter')"
              :placeholder="$t('myBavStatusFilter')"
              :class="[{ 'me-3': !$vuetify.breakpoint.smAndDown }]"
              class="mb-5"
            ></v-select>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.smAndDown" cols="12" md="4" class="px-0"> </v-col>
          <v-col cols="12" md="4" sm="12" class="px-0">
            <v-btn
              color="primary"
              :class="[{ 'mr-3 mb-0': !$vuetify.breakpoint.smAndDown }]"
              class="mb-5 my-change-request__btn"
              @click="createRequestModal = true"
            >
              {{ $t('myBavCreateRequest') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="tableHeaders"
        :mobile-breakpoint="0"
        :items="loadedRequests"
        :loading="loadingRequests"
        :server-items-length="requestsTotal"
        :sort-by.sync="sortingOptions"
        :sort-desc.sync="sortingIsDesc"
        :footer-props="{
          'items-per-page-options': [5, 10, 15],
          'items-per-page-text': $t('itemsPerPage'),
        }"
        class="text-no-wrap"
        @update:items-per-page="setLimit"
        @pagination="handlePagination"
      >
        <template #[`header.trending`]>
          <v-icon size="22">
            {{ icons.mdiTrendingUp }}
          </v-icon>
        </template>

        <template #[`item.issueId`]="{ item }">
          <span class="text-no-wrap th">{{ item.issueId }}</span>
        </template>

        <template #[`item.requestTypeNameEn`]="{ item }">
          <div class="d-flex align-center">
            <div class="d-flex flex-column">
              <span>
                {{ currentLang === 'en' ? item.requestTypeNameEn : item.requestTypeNameDe }}
              </span>
              <!--<span class="text-xs">{{ item.permittedRoles }}</span>-->
            </div>
          </div>
        </template>

        <template #[`item.reporterName`]="{ item }">
          <div class="text-no-wrap">
            <div class="d-flex flex-column">
              <span v-if="item.reporterName" class="">
                {{ item.reporterName }}
              </span>
              <span v-else> - </span>
              <!--<span class="text-xs">{{ item.permittedRoles }}</span>-->
            </div>
          </div>
        </template>

        <template #[`item.createdDate`]="{ item }">
          {{ moment(item.createdDate).format('DD.MM.YYYY') }}
        </template>

        <template #[`item.status`]="{ item }">
          <v-chip class="ma-2" :color="item.status === 'RESOLVED' ? '#37B59D' : '#F9B60D'" text-color="white">
            {{ currentLang === 'en' ? item.statusEN : item.statusDE }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>

    <ModalWindow
      :is-open="createRequestModal"
      :title="$t('contractChangeRequest')"
      hasForm
      is-full-screen
      isFullScreenFixed
    >
      <template #actionsFullScreenClose>
        <v-btn icon dark color="white" @click="(createRequestModal = false), (success = false)">
          <v-icon size="20" class="d-sm-none">{{ icons.mdiClose }}</v-icon>
          <v-icon class="d-none d-sm-block">{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </template>
      <v-card> </v-card>

      <template #content>
        <v-col v-if="!success" cols="12" md="12" lg="12" class="mx-auto pt-0">
          <h2 class="text-center mb-5 mt-4 mt-sm-10 contract-heading">
            {{ $t('contractChangeRequestIntro') }}
          </h2>
        </v-col>
        <v-col v-if="success" cols="12" sm="9" md="8" lg="7" class="mx-auto pt-0">
          <v-card-text class="text-center mt-15 success-msg">
            <v-avatar size="70" color="success" class="mb-5 v-avatar--dialog mt-1">
              <v-icon color="white" size="40">
                {{ icons.mdiCheckBold }}
              </v-icon>
            </v-avatar>
            <h2 class="mb-5">
              {{ $t('contractChangeRequestConfirmHeadline') }}
            </h2>
            <p class="text-body-1 text-center mb-7">
              {{ $t('contractChangeRequestConfirmMsg') }}
            </p>

            <v-btn color="primary" depressed @click="(createRequestModal = false), (success = false)">
              {{ $t('contractChangeRequestOkay') }}
            </v-btn>
          </v-card-text>
        </v-col>
        <v-col v-if="!success" cols="12" sm="9" md="8" lg="7" class="mx-auto pt-0">
          <v-select
            v-model="selectedRequestType"
            class="mb-2"
            :items="requestTypes"
            :label="$t('requestFieldType')"
            :placeholder="$t('requestFieldType')"
            dense
            outlined
          ></v-select>

          <v-form ref="createRequestForm" @submit.prevent="onCreateRequest">
            <p v-if="requestTypeFields && requestTypeFields.length" class="mb-5 text-center primary--text">
              {{ $t('contractChangeRequestLead') }}
            </p>
            <div
              v-for="field in requestTypeFields"
              :key="field.fieldId"
              v-if="
                !JIRA_HIDDEN_FIELD_IDS.includes(field.fieldId) &&
                requestTypeFields &&
                requestTypeFieldsDictionary[field.jiraSchema.type]
              "
            >
              <div
                v-if="field.jiraSchema.type !== 'array' || field.jiraSchema.system === 'attachment'"
                class="form-group"
              >
                <div v-if="requestTypeFieldsDictionary[field.jiraSchema.type] !== 'option'">
                  <div class="flex-1 mb-1">
                    <v-text-field
                      v-if="field.required"
                      v-model="field.value"
                      outlined
                      dense
                      :rules="[validators.required]"
                      :label="currentLang === 'en' ? field.name : field.description + '*'"
                      :type="requestTypeFieldsDictionary[field.jiraSchema.type]"
                    />
                    <v-text-field
                      v-else
                      v-model="field.value"
                      outlined
                      dense
                      :label="currentLang === 'en' ? field.name : field.description"
                      :type="requestTypeFieldsDictionary[field.jiraSchema.type]"
                    />
                  </div>
                </div>
                <v-select
                  v-else
                  v-model="field.value"
                  acceptJSON
                  class="flex-1 mb-2"
                  outlined
                  dense
                  :options="transformValidOptions(field.validValues)"
                  :label="currentLang === 'en' ? field.name : field.description"
                />
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row form-cta">
              <v-btn
                outlined
                depressed
                color="primary"
                class="flex-grow-1 flex-shrink-1 mr-sm-2 order-1 order-sm-0"
                @click="createRequestModal = false"
              >
                {{ $t('buttonCancel') }}
              </v-btn>
              <v-btn
                depressed
                type="submit"
                color="primary"
                class="flex-grow-1 flex-shrink-1 ml-sm-2 order-0 order-sm-1 mb-5 mb-sm-0"
                :disabled="!requestTypeFields.length"
              >
                <span :class="requestTypeFields.length ? 'accent--text' : ''">
                  {{ $t('contractChangeRequestSend') }}</span
                >
              </v-btn>
            </div>
          </v-form>
        </v-col>
      </template>
    </ModalWindow>
  </div>
</template>

<script>
import { ref, toRefs, computed, getCurrentInstance, watch } from '@vue/composition-api';
import moment from 'moment';
import { mdiClose, mdiCheckBold } from '@mdi/js';
import { getEmployeeById } from '../../api/employee';
import {
  getRequestsHistory,
  createRequest,
  getRequestStatuses,
  getRequestTypes,
  getFieldsByRequestTypeId,
} from '../../api/requests';
import { required } from '../../@core/utils/validation';
import ModalWindow from '@/components/modal/ModalWindow.vue';
import { JIRA_FIELDS, JIRA_HIDDEN_FIELD_IDS } from '@/constants';

export default {
  name: 'MyChangeRequests',
  props: ['user', 'sender'],
  components: { ModalWindow },
  setup(props) {
    const vm = getCurrentInstance().proxy;
    const loadedRequests = ref([]);
    const requestTypes = ref([]);
    const requestTypeFields = ref([]);
    const requestsTotal = ref(0);
    const requestStatuses = ref([]);
    const selectedRequestType = ref(null);
    const requestFilter = ref('');
    const createRequestForm = ref(null);
    const loadingRequests = ref(false);
    const createRequestModal = ref(false);
    const limit = ref(10);
    const currentPage = ref(1);
    const offset = ref(0);
    const success = ref(false);

    const { user, sender } = toRefs(props);

    const sortingOptions = ref(['createdDate']);
    const sortingIsDesc = ref(true);

    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const tableHeaders = computed(() => {
      return [
        {
          text: vm.$t('ID'),
          value: 'issueId',
          sortable: false,
          class: 'text-uppercase',
          align: 'left',
        },
        {
          text: vm.$t('requestsTableColumnType'),
          value: 'requestTypeNameEn',
          sortable: false,
          class: 'text-uppercase',
          align: 'left',
        },
        {
          text: vm.$t('Reporter'),
          value: 'reporterName',
          sortable: false,
          class: 'text-uppercase',
          align: 'left',
        },
        {
          text: vm.$t('requestsTableColumnTime'),
          value: 'createdDate',
          sortable: true,
          class: 'text-uppercase',
          align: 'left',
        },
        {
          text: vm.$t('requestsTableColumnStatus'),
          value: 'status',
          sortable: false,
          class: 'text-uppercase',
          align: 'center',
        },
      ];
    });

    const requestTypeFieldsDictionary = {
      string: 'text',
      datetime: 'date',
      date: 'date',
      option: 'option',
      priority: 'option',
    };

    const setLimit = (lim) => {
      limit.value = lim;
      fetchHistory(user.value.id);
    };

    const onNextPage = () => {
      offset.value += limit.value;
      fetchHistory(user.value.id);
    };

    const onPreviousPage = () => {
      offset.value -= limit.value;
      fetchHistory(user.value.id);
    };

    const handlePagination = (event) => {
      if (currentPage.value < event.page) onNextPage();
      if (currentPage.value > event.page) onPreviousPage();

      currentPage.value = event.page;
    };

    const getReporterName = (id) => {
      if (!id) return;
      return new Promise((resolve) => {
        getEmployeeById(id).then((res) => {
          const userName = `${res.data.personalDetails.firstName} ${res.data.personalDetails.lastName}`;
          resolve(userName || '-');
        });
      });
    };

    const fetchHistory = (id) => {
      const sorting = sortingIsDesc.value ? 'DESC' : 'ASC';

      loadingRequests.value = true;
      getRequestsHistory(id, limit.value, offset.value, sorting, requestFilter.value).then((res) => {
        const promiseArr = [];

        loadedRequests.value = res.data.page;
        requestsTotal.value = res.data.total;

        loadedRequests.value.forEach((i) => {
          if (user.value.id !== i.reporter && !sender.value) {
            i.reporterName = vm.$t('linkHeaderRoleHR');
          } else {
            promiseArr.push(
              getReporterName(i.reporter).then((name) => {
                i.reporterName = name;
              }),
            );
          }
        });

        Promise.all(promiseArr).then(() => {
          loadingRequests.value = false;
        });
      });
    };

    const fetchStatuses = () => {
      getRequestStatuses().then((res) => {
        requestStatuses.value = res.data.page.map((i) => {
          if (i.statusCode !== 'ERROR_STATUS') {
            return {
              text: currentLang.value === 'en' ? i.statusEn : i.statusDe,
              value: i.statusCode,
            };
          }
        });
      });
    };

    const fetchTypes = () => {
      getRequestTypes().then((res) => {
        requestTypes.value = res.data.map((i) => {
          return {
            text: currentLang.value === 'en' ? i.requestTypeNameEn : i.requestTypeNameDe,
            value: i.requestTypeId,
          };
        });
      });
    };

    const fetchFields = (requestTypeId) => {
      if (!requestTypeId) return;

      getFieldsByRequestTypeId(requestTypeId).then((res) => {
        requestTypeFields.value = res.data.requestTypeFields;
        vm.$nextTick(() => {
          createRequestForm.value.reset();
        });
      });
    };

    const transformValidOptions = (arr) => {
      return arr.map((i) => ({
        text: i.label,
        value: JSON.stringify({ value: i.label }),
      }));
    };

    const fillHiddenFields = (fields) => {
      return fields.map((i) => {
        if (JIRA_HIDDEN_FIELD_IDS.includes(i.fieldId)) {
          if (i.fieldId === JIRA_FIELDS.NAME) {
            return { ...i, value: `${user.value.username} (${user.value.firstName} ${user.value.lastName})` };
          }
        } else {
          return i;
        }
      });
    };

    const onCreateRequest = () => {
      const isValid = createRequestForm.value.validate();
      if (!isValid) return;

      const fieldValues = fillHiddenFields(requestTypeFields.value).reduce(
        (obj, i) => ((obj[i.fieldId] = i.value), obj),
        {},
      );
      const payload = {
        raiseOnBehalfOf: sender.value ? sender.value.username : user.value.username || '',
        requestTypeId: selectedRequestType.value,
        requestTypeName: requestTypes.value.filter((i) => i.value === selectedRequestType.value)[0].text,
        requestFieldValues: fieldValues,
        employeeId: user.value.id,
        senderId: sender.value ? sender.value.id : user.value.id,
      };

      createRequest(payload).then(() => {
        requestTypeFields.value = [];
        selectedRequestType.value = null;
        success.value = true;
        fetchHistory(user.value.id);
      });
    };

    fetchHistory(user.value.id);
    fetchTypes();
    fetchStatuses();

    watch(selectedRequestType, (v) => {
      fetchFields(v);
    });

    watch(currentLang, () => {
      fetchTypes();
      fetchStatuses();
    });

    watch(createRequestModal, () => {
      requestTypeFields.value = [];
      selectedRequestType.value = null;
    });

    watch(sortingIsDesc, () => {
      fetchHistory(user.value.id);
    });

    watch(requestFilter, () => {
      fetchHistory(user.value.id);
    });

    return {
      JIRA_HIDDEN_FIELD_IDS,
      limit,
      loadedRequests,
      loadingRequests,
      tableHeaders,
      createRequestModal,
      requestTypes,
      moment,
      selectedRequestType,
      requestTypeFields,
      requestTypeFieldsDictionary,
      currentLang,
      createRequestForm,
      requestsTotal,
      sortingOptions,
      sortingIsDesc,
      requestStatuses,
      requestFilter,
      setLimit,
      handlePagination,
      transformValidOptions,
      onCreateRequest,
      success,
      fetchHistory,
      // icons
      icons: {
        mdiClose,
        mdiCheckBold,
      },

      validators: { required },
    };
  },
};
</script>

<style lang="scss">
.th {
  max-width: 280px;
  overflow: hidden;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  display: block;
}
.form-cta button {
  flex-basis: 50%;
}
.v-application .theme--light .success-msg {
  h2 {
    color: #505a68 !important;
  }
}
.my-change-request {
  &__btn {
    min-width: 280px !important;
    border-radius: 3px !important;
  }
  &__table {
    .v-select__selection {
      color: #2830ce !important;
    }

    .v-data-table__checkbox,
    .v-input--checkbox {
      .v-icon__svg,
      .v-icon {
        color: #2830ce !important;
      }
    }

    .v-data-footer {
      color: #2830ce !important;
      border-color: rgba(40, 48, 206, 0.25) !important;
      button.v-btn--disabled {
        opacity: 0.4;
      }
      svg {
        fill: #2830ce;
      }
    }
    th {
      background: rgba(40, 48, 206, 0.25) !important;
      border-top: 1px solid rgba(40, 48, 206, 0.15) !important;
      border-color: #2830ce !important;
      border-bottom: none !important;
      span {
        color: #2830ce !important;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
      }
    }
    tr,
    td {
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-card {
      border-radius: 3px !important;
      margin-top: 31px;
      border-color: rgba(40, 48, 206, 0.25) !important;
    }
    &-heading {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #505a68;
    }
    &-filter {
      /*max-width: 320px;*/
      min-width: 320px;
    }
    &-info {
      padding: 10px 8px;
      border: 1px solid #2830ce;
      border-radius: 3px;
      margin: 8px 0;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      background-color: white;
      /*transform: translateX(50%) translateY(10px);*/
      color: #505a68;

      ul {
        padding: 0;
        padding-left: 12px;
      }
      &-title {
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
        color: #2830ce;
        margin-bottom: 3px;
      }
    }
  }
}
.contract-heading {
  font-size: 24px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .form-cta button {
    flex-basis: auto;
  }
  .contract-heading {
    font-size: 16px;
  }

  .v-application .theme--light .success-msg {
    h2 {
      font-size: 1rem;
    }
  }
}

@media only screen and (max-width: 959px) {
  .my-change-request {
    &__btn {
      min-width: 100% !important;
    }
    &__table {
      table {
        border: 1px solid rgba(40, 48, 206, 0.15) !important;
      }
    }
    .v-data-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
    .v-data-footer__icons-after,
    .v-data-footer__icons-before {
      max-width: 16px;
      max-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-data-footer__select {
      min-width: unset !important;
    }
    .v-data-footer__pagination {
      margin: 0 10px;
    }
  }
}
</style>
