<template>
  <v-row class="user-bio-panel">
    <!-- user profile -->
    <v-col cols="12">
      <v-card class="v-card--cc">
        <v-card-title class="justify-center flex-column">
          <v-badge color="accent" bottom offset-x="24" offset-y="42">
            <v-avatar
              :color="userData.avatar ? '' : 'teal'"
              :class="userData.avatar ? '' : 'v-avatar accent--text'"
              size="120"
              class="mb-2"
            >
              <v-img
                v-if="userData.avatar"
                :src="require(`@/assets/images/avatars/${userData.avatar}`)"
                gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
              ></v-img>

              <span v-else class="text-5xl">{{ initials }}</span>

              <!-- <v-img
               gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            >
             <span
              class="text-5xl"
            >{{ initials }}</span></v-img> -->
            </v-avatar>
          </v-badge>

          <span>{{ userData.firstName }} {{ userData.lastName }} </span>

          <v-chip
            v-if="isMyProfile"
            color="teal"
            small
            :class="`v-chip px-5 py-3 accent--text v-chip--no-color text-sm font-weight-semibold text-capitalize`"
          >
            {{
              activeRole == 'ROLE_EMPLOYEE'
                ? $t('linkHeaderRoleEmployee')
                : activeRole == 'ROLE_BAV_MANAGER'
                ? $t('linkHeaderRoleHR')
                : activeRole == 'ROLE_KEY_ACCOUNT'
                ? $t('linkHeaderRoleAM')
                : ''
            }}
          </v-chip>
          <v-chip
            v-else
            label
            small
            color="info"
            :class="`v-chip-light-bg text-sm font-weight-semibold primary--text text-capitalize`"
          >
            {{ $t('linkHeaderRoleEmployee') }}
          </v-chip>
        </v-card-title>

        <v-card-text class="mt-md-7 pl-0 pr-0 pl-md-4 pr-md-4 pb-0 pb-md-3">
          <div class="text-xl font-weight-bold pl-3 pr-3">
            {{ $t('overview') }}
          </div>

          <v-divider color="teal" class="ml-3 mr-3"></v-divider>

          <v-list class="text-base">
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelCompany') }}: </span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ companyConfig.employeeFacingName }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelPersonalNumber') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.personalNumber || '-' }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('bavStatus') }}: </span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">
                  <v-chip
                    small
                    color="accent"
                    :class="`teal--text pa-3 text-sm v-chip--no-color font-weight-bold text-capitalize`"
                  >
                    {{ $t(contractStatus) }}
                  </v-chip>
                </span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelCountry') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.country || '-' }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelLanguagePreference') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ userData.languagePreference.language.toUpperCase() }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelPhone') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.phoneNumber || '-' }}</span>
              </v-col>
            </v-list-item>
            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('formLabelEmailPersonal') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ personalDetails.emailPersonal || '-' }}</span>
              </v-col>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <v-col cols="6">
                <span class="text--secondary me-2">{{ $t('CONTACT_METHOD') }}:</span>
              </v-col>
              <v-col cols="6">
                <span class="text--secondary">{{ $t(personalDetails.contactMethod) || '-' }}</span>
              </v-col>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions
          v-if="!isMyProfile && (activeRole == 'ROLE_BAV_MANAGER' || activeRole == 'ROLE_KEY_ACCOUNT')"
          class="pl-0 pr-0 pl-md-4 pr-md-4 pb-3 pb-md-4"
        >
          <v-col cols="12">
            <v-btn
              v-if="activeRole == 'ROLE_BAV_MANAGER' && $parent.canChangeContract"
              block
              class="px-sm-5 mb-4"
              color="primary"
              @click="createRequestModal = true"
            >
              {{ $t('myBavRequestContractChange') }}
            </v-btn>
            <v-btn class="px-sm-5" block color="error" @click="deleteEmployeeModal = true">
              {{ $t('headlineDeleteEmployee') }}
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
      <ModalWindow :is-open="deleteEmployeeModal" error>
        <template #content>
          <p
            class="text-base"
            v-html="
              $t('profileDeleteUser', {
                USER: personalDetails.firstName + ' ' + personalDetails.lastName,
              })
            "
          ></p>
        </template>
        <template #actions>
          <v-btn color="primary" outlined @click="deleteEmployeeModal = false">
            {{ $t('buttonCancel') }}
          </v-btn>
          <v-btn color="primary" @click="confirmDelete">
            {{ $t('buttonConfirm') }}
          </v-btn>
        </template>
      </ModalWindow>
      <ModalWindow
        :is-open="createRequestModal"
        :title="$t('contractChangeRequest')"
        hasForm
        is-full-screen
        isFullScreenFixed
      >
        <template #actionsFullScreenClose>
          <v-btn icon dark color="white" @click="(createRequestModal = false), (success = false)">
            <v-icon size="20" class="d-sm-none">{{ icons.mdiClose }}</v-icon>
            <v-icon class="d-none d-sm-block">{{ icons.mdiClose }}</v-icon>
          </v-btn>
        </template>
        <v-card> </v-card>

        <template #content>
          <v-col v-if="!success" cols="12" md="12" lg="12" class="mx-auto pt-0">
            <h2 v-if="activeRole !== 'ROLE_BAV_MANAGER'" class="text-center mb-5 mt-4 mt-sm-10 contract-heading">
              {{ $t('contractChangeRequestIntro') }}
            </h2>
            <h2 v-else class="text-center mb-5 mt-4 mt-sm-10 contract-heading">
              {{ $t('contractChangeRequestIntroHr') }}
            </h2>
          </v-col>
          <v-col v-if="success" cols="12" sm="9" md="8" lg="7" class="mx-auto pt-0">
            <v-card-text class="text-center mt-15 success-msg">
              <v-avatar size="70" color="success" class="mb-5 v-avatar--dialog mt-1">
                <v-icon color="white" size="40">
                  {{ icons.mdiCheckBold }}
                </v-icon>
              </v-avatar>
              <h2 class="mb-5">
                {{ $t('contractChangeRequestConfirmHeadline') }}
              </h2>
              <p class="text-body-1 text-center mb-7">
                {{ $t('contractChangeRequestConfirmMsg') }}
              </p>

              <v-btn
                color="primary"
                depressed
                @click="(createRequestModal = false), (success = false), $emit('switchToTab')"
              >
                {{ $t('contractChangeRequestOkay') }}
              </v-btn>
            </v-card-text>
          </v-col>
          <v-col v-if="!success" cols="12" sm="9" md="8" lg="7" class="mx-auto pt-0">
            <v-select
              v-model="selectedRequestType"
              class="mb-2"
              :items="requestTypes"
              :label="$t('requestFieldType')"
              :placeholder="$t('requestFieldType')"
              dense
              outlined
            ></v-select>

            <v-form ref="createRequestForm" @submit.prevent="onCreateRequest">
              <p v-if="requestTypeFields && requestTypeFields.length" class="mb-5 text-center primary--text">
                {{ $t('contractChangeRequestLead') }}
              </p>
              <div
                v-for="field in requestTypeFields"
                :key="field.fieldId"
                v-if="
                  !JIRA_HIDDEN_FIELD_IDS.includes(field.fieldId) &&
                  requestTypeFields &&
                  requestTypeFieldsDictionary[field.jiraSchema.type]
                "
              >
                <div
                  v-if="field.jiraSchema.type !== 'array' || field.jiraSchema.system === 'attachment'"
                  class="form-group"
                >
                  <div v-if="requestTypeFieldsDictionary[field.jiraSchema.type] !== 'option'">
                    <div class="flex-1 mb-1">
                      <v-text-field
                        v-if="field.required"
                        v-model="field.value"
                        outlined
                        dense
                        :rules="[validators.required]"
                        :label="currentLang === 'en' ? field.name : field.description + '*'"
                        :type="requestTypeFieldsDictionary[field.jiraSchema.type]"
                      />
                      <v-text-field
                        v-else
                        v-model="field.value"
                        outlined
                        dense
                        :label="currentLang === 'en' ? field.name : field.description"
                        :type="requestTypeFieldsDictionary[field.jiraSchema.type]"
                      />
                    </div>
                  </div>
                  <v-select
                    v-else
                    v-model="field.value"
                    acceptJSON
                    class="flex-1 mb-2"
                    outlined
                    dense
                    :options="transformValidOptions(field.validValues)"
                    :label="currentLang === 'en' ? field.name : field.description"
                  />
                </div>
              </div>
              <div class="d-flex flex-column flex-sm-row form-cta">
                <v-btn
                  outlined
                  depressed
                  color="primary"
                  class="flex-grow-1 flex-shrink-1 mr-sm-2 order-1 order-sm-0"
                  @click="createRequestModal = false"
                >
                  {{ $t('buttonCancel') }}
                </v-btn>
                <v-btn
                  depressed
                  type="submit"
                  color="primary"
                  class="flex-grow-1 flex-shrink-1 ml-sm-2 order-0 order-sm-1 mb-5 mb-sm-0"
                  :disabled="!requestTypeFields.length"
                >
                  <span :class="requestTypeFields.length ? 'accent--text' : ''">
                    {{ $t('contractChangeRequestSend') }}</span
                  >
                </v-btn>
              </div>
            </v-form>
          </v-col>
        </template>
      </ModalWindow>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCheck, mdiBriefcaseVariantOutline, mdiCheckboxBlankCircle, mdiClose, mdiCheckBold } from '@mdi/js';
import { avatarText, kFormatter } from '@core/utils/filter';
import { getCurrentInstance, ref, toRefs, onMounted, computed, watch } from '@vue/composition-api';
import { required } from '@core/utils/validation';
import moment from 'moment';
import ModalWindow from '@/components/modal/ModalWindow.vue';
import { CONTRACT_TRANSLATION_MAP, JIRA_FIELDS, JIRA_HIDDEN_FIELD_IDS } from '@/constants';
import { deleteEmployee, getContractByEmployeeId } from '@/api/employee';
import { createRequest, getRequestStatuses, getRequestTypes, getFieldsByRequestTypeId } from '@/api/requests';

export default {
  components: { ModalWindow },
  props: ['personalDetails', 'companyConfig', 'userData', 'isMyProfile', 'sender', 'user'],
  setup(props) {
    const vm = getCurrentInstance().proxy;

    const deleteEmployeeModal = ref(false);
    const { personalDetails } = toRefs(props);
    const userData = ref({});

    const contract = getContractByEmployeeId(props.userData.id).then((response) => response);
    userData.value = {
      ...props.userData,
      status: {
        contractStatus: contract.contractStatus,
      },
    };

    const { activeRole } = vm.$store.getters;

    const contractStatus = computed(() => {
      return CONTRACT_TRANSLATION_MAP[userData.value.status.contractStatus];
    });

    const initials = computed(() => {
      if (personalDetails.value.firstName) {
        return getInitials(`${personalDetails.value.firstName} ${personalDetails.value.lastName}`);
      }
      return getInitials(personalDetails.value.email || personalDetails.value.username);
    });

    const getInitials = (str) => {
      const names = str.split(' ');
      let initials = names[0].substring(0, 1).toUpperCase();

      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }

      return initials;
    };

    const confirmDelete = () => {
      deleteEmployee(vm.$route.params.id).then(() => {
        deleteEmployeeModal.value = false;
        vm.$router.push('/');
      });
    };
    const requestTypes = ref([]);
    const requestTypeFields = ref([]);
    const requestStatuses = ref([]);
    const selectedRequestType = ref(null);
    const requestFilter = ref('');
    const createRequestForm = ref(null);
    const createRequestModal = ref(false);
    const success = ref(false);
    const { sender, user } = toRefs(props);
    const currentLang = computed(() => vm.$store.state.app.currentLang);
    const requestTypeFieldsDictionary = {
      string: 'text',
      datetime: 'date',
      date: 'date',
      option: 'option',
      priority: 'option',
    };

    // NOTE: For now we are duplicating the create requests modal
    // because it cant be triggered until the tab is opened
    // maybe in future we can seperate modal from the table
    // https://degura.atlassian.net/browse/TECH-810

    const fetchStatuses = () => {
      getRequestStatuses().then((res) => {
        requestStatuses.value = res.data.page.map((i) => {
          if (i.statusCode !== 'ERROR_STATUS') {
            return {
              text: currentLang.value === 'en' ? i.statusEn : i.statusDe,
              value: i.statusCode,
            };
          }
        });
      });
    };
    const fetchTypes = () => {
      getRequestTypes().then((res) => {
        requestTypes.value = res.data.map((i) => {
          return {
            text: currentLang.value === 'en' ? i.requestTypeNameEn : i.requestTypeNameDe,
            value: i.requestTypeId,
          };
        });
      });
    };

    const fetchFields = (requestTypeId) => {
      if (!requestTypeId) return;

      getFieldsByRequestTypeId(requestTypeId).then((res) => {
        requestTypeFields.value = res.data.requestTypeFields;
        vm.$nextTick(() => {
          createRequestForm.value.reset();
        });
      });
    };
    const transformValidOptions = (arr) => {
      return arr.map((i) => ({
        text: i.label,
        value: JSON.stringify({ value: i.label }),
      }));
    };

    const fillHiddenFields = (fields) => {
      return fields.map((i) => {
        if (JIRA_HIDDEN_FIELD_IDS.includes(i.fieldId)) {
          if (i.fieldId === JIRA_FIELDS.NAME) {
            const userIdentifier =
              user.value.personalDetails.username ||
              user.value.personalDetails.emailBusiness ||
              user.value.personalDetails.emailPersonal ||
              'No email';
            return {
              ...i,
              value: `${userIdentifier} (${user.value.personalDetails.firstName} ${user.value.personalDetails.lastName})`,
            };
          }
        } else {
          return i;
        }
      });
    };

    const onCreateRequest = () => {
      const isValid = createRequestForm.value.validate();
      if (!isValid) return;

      const fieldValues = fillHiddenFields(requestTypeFields.value).reduce(
        (obj, i) => ((obj[i.fieldId] = i.value), obj),
        {},
      );
      const payload = {
        raiseOnBehalfOf: sender.value ? sender.value.username : user.value.username || '',
        requestTypeId: selectedRequestType.value,
        requestTypeName: requestTypes.value.filter((i) => i.value === selectedRequestType.value)[0].text,
        requestFieldValues: fieldValues,
        employeeId: user.value.id,
        senderId: sender.value ? sender.value.id : user.value.id,
      };

      createRequest(payload).then(() => {
        requestTypeFields.value = [];
        selectedRequestType.value = null;
        success.value = true;
        vm.$emit('switchToTabRequests');
      });
    };

    fetchTypes();
    fetchStatuses();
    watch(selectedRequestType, (v) => {
      fetchFields(v);
    });

    watch(currentLang, () => {
      fetchTypes();
      fetchStatuses();
    });

    watch(createRequestModal, () => {
      requestTypeFields.value = [];
      selectedRequestType.value = null;
    });

    return {
      // resolveUserStatusVariant,
      // resolveUserRoleVariant,
      JIRA_HIDDEN_FIELD_IDS,
      avatarText,
      kFormatter,
      initials,
      activeRole,
      contractStatus,
      deleteEmployeeModal,
      confirmDelete,
      createRequestModal,
      requestTypes,
      moment,
      selectedRequestType,
      requestTypeFields,
      requestTypeFieldsDictionary,
      currentLang,
      createRequestForm,
      requestStatuses,
      requestFilter,
      transformValidOptions,
      onCreateRequest,
      success,

      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiClose,
        mdiCheckBold,
      },
      validators: { required },
    };
  },
};
</script>
<style lang="scss">
.v-application.skin-variant--bordered .theme--light.v-card--cc:not(.v-card .v-card) {
  border: 1px solid #f3f3f3;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 1px;
}
</style>
